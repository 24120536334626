<template>
  <CStack
    ref="container"
    @mousedown="handleMouseDown"
    :cursor="cursor"
    :user-select="userSelect"
    :direction="direction"
    overflow-x="scroll"
    overflow-y="visible"
    :pb="pb"
  >
    <slot></slot>
  </CStack>
</template>
<script>
import { CStack } from "@chakra-ui/vue";
export default {
  props: {
    direction: String,
    pb: String,
  },
  components: {
    CStack,
  },
  // mounted() {
  //     this.$refs.container.$el.scrollTop = this.$refs.container.$el.scrollHeight;
  // },
  data() {
    return {
      position: {
        left: 0,
        top: 0,
        x: 0,
        y: 0,
      },
      cursor: "grab",
      userSelect: "",
    };
  },
  methods: {
    handleMouseDown(e) {
      this.cursor = "grabbing";
      this.userSelect = "none";
      this.position.left = this.$refs.container.$el.scrollLeft;
      this.position.top = this.$refs.container.$el.scrollTop;
      this.position.x = e.clientX;
      this.position.y = e.clientY;

      document.addEventListener("mousemove", this.mouseMoveHandler);
      document.addEventListener("mouseup", this.mouseUpHandler);
    },
    mouseMoveHandler(e) {
      const dx = e.clientX - this.position.x;
      const dy = e.clientY - this.position.y;
      this.$refs.container.$el.scrollLeft = this.position.left - dx;
      this.$refs.container.$el.scrollTop = this.position.top - dy;
    },
    mouseUpHandler() {
      document.removeEventListener("mousemove", this.mouseMoveHandler);
      document.removeEventListener("mouseup", this.mouseUpHandler);

      this.cursor = "grab";
    },
  },
};
</script>
