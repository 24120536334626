<template>
  <CBox>
    <CBox>
      <Loading :active="isLoading" />
      <CGrid
        :templateColumns="
          currentAppType.view_type == 'RECRUITMENT'
            ? '12fr'
            : ['12fr', '12fr', '8fr 4fr', '8fr 4fr']
        "
        gap="3"
      >
        <CBox min-w="100px" w="100%" max-w="100%" min-h="100vh">
          <CBox
            bg="white"
            w="100%"
            borderRadius="10px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox>
              <CBox py="40px" px="20px">
                <CText
                  whiteSpace="wrap"
                  mb="10px"
                  fontFamily="Montserrat"
                  :fontSize="['20px', '24px', '34px', '36px']"
                  fontWeight="bolder"
                  color="fontMain"
                  >{{ currentAppType.name }}</CText
                >
                <CText
                  pb="20px"
                  fontFamily="Montserrat"
                  color="fontMain"
                  fontSize="14px"
                  v-html="applyFontSizes(currentAppType.description)"
                ></CText>
                <CBox pb="20px" v-if="currentAppType.design_link">
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    >Design URL</CText
                  >
                  <CLink
                    color="linkColor"
                    fontFamily="Montserrat"
                    fontSize="14px"
                    :href="currentAppType.design_link"
                    target="_blank"
                    >Klik link untuk membuka</CLink
                  >
                </CBox>
                <CBox pb="20px" v-if="currentAppType.postman_link">
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    >API Documentation</CText
                  >
                  <CLink
                    color="linkColor"
                    fontFamily="Montserrat"
                    fontSize="14px"
                    :href="currentAppType.postman_link"
                    target="_blank"
                    >Klik link untuk membuka</CLink
                  >
                </CBox>
                <!-- <CBox pb="20px" v-if="currentAppType.settings && currentAppType.settings.youtube_id">
                  <iframe width="560" height="315" :src="'https://www.youtube-nocookie.com/embed/'+currentAppType.settings.youtube_id" title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>
                </CBox> -->
                <CBox pb="20px" v-if="currentAppType.requirement_doc_link">
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    >Requirement Docs</CText
                  >
                  <CLink
                    color="linkColor"
                    fontFamily="Montserrat"
                    fontSize="14px"
                    :href="currentAppType.requirement_doc_link"
                    target="_blank"
                    >Klik link untuk membuka</CLink
                  >
                </CBox>
                <CStack
                  style="flex-wrap: wrap"
                  d="flex"
                  direction="row"
                  v-if="
                    currentTrack.stack_tech &&
                    currentTrack.stack_tech.length > 0 &&
                    currentAppType.stack_tech &&
                    currentAppType.stack_tech.length > 0
                  "
                >
                  <CBox h="40px">
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="fontMain"
                        fontSize="14px"
                        fontWeight="bolder"
                        >List Stack:
                      </CText>
                    </CBox>
                  </CBox>
                  <CBox
                    bg="bgSocialBadge"
                    borderRadius="full"
                    h="40px"
                    v-for="(v, i) in currentTrack.stack_tech"
                    :key="i"
                  >
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                      v-if="v.icon"
                    >
                      <CImage
                        :src="require(`@/assets/icon/${v.icon}.png`)"
                        pl="5px"
                        py="5px"
                        w="28px"
                      />
                    </CBox>
                    <CBox
                      d="table-cell"
                      verticalAlign="middle"
                      h="inherit"
                      px="10px"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="fontMain"
                        fontSize="12px"
                        fontWeight="600"
                        >{{ v.name }}</CText
                      >
                    </CBox>
                  </CBox>
                  <!-- <CBox bg="bgStackBadge" borderRadius="full">
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                    >
                      <CImage
                        :src="require('@/assets/icon/ic-nodejs.png')"
                        pl="5px"
                        py="5px"
                      />
                    </CBox>
                    <CBox
                      d="table-cell"
                      verticalAlign="middle"
                      h="inherit"
                      px="10px"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="white"
                        fontSize="12px"
                        >NodeJS</CText
                      >
                    </CBox>
                  </CBox> -->
                </CStack>
                <CStack
                  style="flex-wrap: wrap"
                  d="flex"
                  direction="row"
                  v-else-if="
                    currentAppType.stack_tech &&
                    currentAppType.stack_tech.length > 0
                  "
                >
                  <CBox h="40px">
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="fontMain"
                        fontSize="14px"
                        fontWeight="bolder"
                        >List Stack:
                      </CText>
                    </CBox>
                  </CBox>
                  <CBox
                    bg="bgSocialBadge"
                    borderRadius="full"
                    h="40px"
                    v-for="(v, i) in currentAppType.stack_tech"
                    :key="i"
                  >
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                      v-if="v.icon"
                    >
                      <CImage
                        :src="require(`@/assets/icon/${v.icon}.png`)"
                        pl="5px"
                        py="5px"
                        w="28px"
                      />
                    </CBox>
                    <CBox
                      d="table-cell"
                      verticalAlign="middle"
                      h="inherit"
                      px="10px"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="fontMain"
                        fontSize="12px"
                        fontWeight="600"
                        >{{ v.name }}</CText
                      >
                    </CBox>
                  </CBox>
                  <!-- <CBox bg="bgStackBadge" borderRadius="full">
                    <CBox
                      w="inherit"
                      h="inherit"
                      d="table-cell"
                      verticalAlign="middle"
                    >
                      <CImage
                        :src="require('@/assets/icon/ic-nodejs.png')"
                        pl="5px"
                        py="5px"
                      />
                    </CBox>
                    <CBox
                      d="table-cell"
                      verticalAlign="middle"
                      h="inherit"
                      px="10px"
                    >
                      <CText
                        fontFamily="Montserrat"
                        color="white"
                        fontSize="12px"
                        >NodeJS</CText
                      >
                    </CBox>
                  </CBox> -->
                </CStack>
              </CBox>
            </CBox>
            <CDivider />
            <!-- <CBox my="30px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="bold"
                color="fontMain"
                >Detail Instruksi</CText
              >
            </CBox> -->
            <CBox
              mx="20px"
              mb="20px"
              minH="100px"
              v-for="(v, i) in currentTestScenarios.list"
              :key="i"
              borderRadius="5px"
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.1)"
            >
              <CBox mx="20px" py="20px">
                <CText
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="bold"
                  color="fontMain"
                  >{{ v.title }}</CText
                >
                <CBox v-if="v.description" px="10px">
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    color="fontMain"
                    >{{ v.description }}</CText
                  >
                  <CList
                    styleType="disc"
                    v-if="v.description_list && v.description_list.length > 0"
                    pl="10px"
                  >
                    <CListItem
                      v-for="(v12, i12) in v.description_list"
                      :key="i12"
                      fontFamily="Montserrat"
                      fontSize="14px"
                      color="fontMain"
                      >{{ v12 }}</CListItem
                    >
                  </CList>
                </CBox>
              </CBox>
              <CBox>
                <CDivider mb="0px" />
                <CBox
                  px="20px"
                  py="10px"
                  class="pointer"
                  d="flex"
                  justifyContent="space-between"
                  bgColor="bgTestList"
                  @click.native="
                    $store.state.testScenarios.testScenarios.list[i].isOpen =
                      !v.isOpen
                  "
                >
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="bold"
                    color="activeMenu"
                    >Detail Test Scenario</CText
                  >
                  <CBox>
                    <CIcon v-if="!v.isOpen" name="chevron-down" />
                    <CIcon v-if="v.isOpen" name="chevron-up" />
                  </CBox>
                </CBox>
                <CCollapse :isOpen="v.isOpen">
                  <CBox
                    px="20px"
                    pt="20px"
                    v-for="(v2, i2) in v.list"
                    :key="i2"
                    bgColor="bgTestList"
                  >
                    <CText
                      fontFamily="Montserrat"
                      fontSize="14px"
                      fontWeight="bold"
                      color="activeMenu"
                      >{{ v2.title }}</CText
                    >
                    <CText
                      v-if="v2.description"
                      fontFamily="Montserrat"
                      fontSize="14px"
                      color="fontMain"
                      >{{ v2.description }}</CText
                    >
                    <CList
                      styleType="disc"
                      v-if="v2.list && v2.list.length > 0"
                      pl="10px"
                    >
                      <CListItem
                        v-for="(v21, i21) in v2.list"
                        :key="i21"
                        fontFamily="Montserrat"
                        fontSize="14px"
                        color="fontMain"
                        >{{ v21 }}</CListItem
                      >
                    </CList>
                  </CBox>
                  <CBox bgColor="bgTestList" h="40px" />
                  <CBox v-if="v.component" pt="10px" pl="10px">
                    <CText
                      fontFamily="Montserrat"
                      fontSize="14px"
                      color="fontMain"
                      >{{ v.component }}</CText
                    >
                    <CList
                      styleType="disc"
                      v-if="v.component_list && v.component_list.length > 0"
                      pl="10px"
                    >
                      <CListItem
                        v-for="(v13, i) in v.component_list"
                        :key="i"
                        fontFamily="Montserrat"
                        fontSize="14px"
                        color="fontMain"
                        >{{ v13 }}</CListItem
                      >
                    </CList>
                  </CBox>
                </CCollapse>
              </CBox>
            </CBox>
            <CBox
              v-if="!currentUser || isReachDeadline() || !isChallengeBegin()"
              px="10px"
              py="10px"
              borderRadius="4px"
              bgColor="bgFailedState"
            >
              <CText
                display="inline"
                fontFamily="Montserrat"
                color="failedColor"
                fontWeight="bold"
                fontSize="14px"
                >{{
                  currentUser
                    ? !isChallengeBegin()
                      ? currentAppType.alert_message.submissionNotStarted +
                        " - " +
                        this.formatToLocalDate(currentAppType.start_date)
                      : isReachDeadline() &&
                        currentAppType.alert_message.submissionClosed
                      ? currentAppType.alert_message.submissionClosed
                      : ""
                    : currentAppType.alert_message.unauthorizedUser
                }}</CText
              >
              <CText
                v-if="!currentUser"
                display="inline"
                fontFamily="Montserrat"
                color="failedColor"
                fontWeight="bold"
                fontSize="14px"
                >,<span
                  style="color: #29aafe; cursor: pointer"
                  @click="redirectToSignIn"
                >
                  Click here</span
                >
                to login</CText
              >
            </CBox>
            <CBox
              px="20px"
              pt="40px"
              pb="20px"
              v-if="!['LOGIC', 'PLAYGROUND'].includes(currentAppType.type)"
            >
              <CText
                fontFamily="Montserrat"
                fontSize="20px"
                color="fontMain"
                fontWeight="bold"
                >Submit Form</CText
              >
            </CBox>
            <CBox
              px="20px"
              v-if="
                !checkEmpObject(currentAppType) &&
                !['LOGIC', 'PLAYGROUND'].includes(currentAppType.type)
              "
            >
              <CFormControl mb="20px">
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  w="100%"
                >
                  <CFormLabel
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    d="flex"
                    alignItems="center"
                    >{{ currentAppType.form_label.name }}</CFormLabel
                  >
                  <CInput
                    :maxLength="currentAppType.form_label.nameMaxLength"
                    :disabled="
                      !currentUser || isReachDeadline() || !isChallengeBegin()
                    "
                    type="text"
                    placeholder="Type your description here"
                    v-model="form.siteName"
                    @input="changeStatus('siteName')"
                  ></CInput>
                </CGrid>
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="6"
                  w="100%"
                  v-if="statusForm.siteName"
                >
                  <CBox></CBox>
                  <CFormHelperText
                    v-if="!$v.form.siteName.required"
                    color="red"
                    fontSize="12px"
                    >{{ currentAppType.form_label.name }} wajib
                    diisi</CFormHelperText
                  >
                </CGrid>
              </CFormControl>
              <CFormControl mb="20px">
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  w="100%"
                >
                  <CFormLabel
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    d="flex"
                    alignItems="center"
                    >{{ currentAppType.form_label.url }}</CFormLabel
                  >
                  <CBox>
                    <CGrid
                      v-if="
                        currentAppType.type === 'MOBILE' &&
                        currentProgressBar['progressApp'] > 0
                      "
                      templateColumns="10fr 1fr"
                      :gap="2"
                      w="100%"
                      mb="5px"
                    >
                      <CProgress
                        has-stripe
                        is-animated
                        :value="currentProgressBar['progressApp']"
                        pt="2px"
                      />
                      <CText
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="12px"
                        fontWeight="bold"
                        color="fontMain"
                        >{{ currentProgressBar["progressApp"] }} %</CText
                      >
                    </CGrid>
                    <CBox d="flex" style="gap: 12px">
                      <CInput
                        :disabled="
                          !currentUser ||
                          isReachDeadline() ||
                          !isChallengeBegin()
                        "
                        :maxLength="currentAppType.form_label.nameMaxLength"
                        v-if="
                          currentAppType.type === 'FE' ||
                          currentAppType.type === 'BE'
                        "
                        type="text"
                        :placeholder="currentAppType.form_label.urlPlaceholder"
                        v-model="form.siteUrl"
                        @input="changeStatus('siteUrl')"
                      ></CInput>
                      <ButtonBlue
                        v-if="currentAppType.type === 'MOBILE'"
                        :disabled="
                          !currentUser ||
                          isReachDeadline() ||
                          isUploading ||
                          !isChallengeBegin()
                        "
                        @click.native="showFileChooser"
                        label="Upload"
                        icon="upload"
                        iconPosition="right"
                      />
                      <ButtonBlue
                        v-if="currentAppType.type === 'MOBILE' && isUploading"
                        @click.native="cancelUpload"
                        label="Cancel"
                      />
                      <CBox
                        v-if="
                          currentAppType.type === 'MOBILE' &&
                          form.siteUrl &&
                          !isUploading
                        "
                        d="flex"
                        class="pointer"
                        ml="10px"
                        @click.native="openApp(form.siteUrl)"
                      >
                        <CText
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="14px"
                          fontWeight="600"
                          color="activeMenu"
                          pt="10px"
                          >Aplikasi</CText
                        >
                        <CImage
                          pl="5px"
                          h="20px"
                          w="20px"
                          :src="require('@/assets/icon/ic-link.svg')"
                        />
                      </CBox>
                      <input
                        type="file"
                        ref="input"
                        @input="dispatchUploadProcess"
                        style="display: none"
                      />
                    </CBox>
                  </CBox>
                </CGrid>
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="6"
                  w="100%"
                  v-if="statusForm.siteUrl"
                >
                  <CBox></CBox>
                  <CBox>
                    <CFormHelperText
                      v-if="!$v.form.siteUrl.required"
                      color="red"
                      fontSize="12px"
                      >{{ currentAppType.form_label.url }} wajib
                      diisi</CFormHelperText
                    >
                    <CFormHelperText
                      v-if="
                        currentAppType.type === 'FE' &&
                        $v.form.siteUrl.required &&
                        !$v.form.siteUrl.url
                      "
                      color="red"
                      fontSize="12px"
                      >{{ currentAppType.form_label.url }} tidak
                      valid</CFormHelperText
                    >
                  </CBox>
                </CGrid>
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  w="100%"
                >
                  <CBox />
                  <CFormHelperText
                    v-if="currentAppType.type === 'BE'"
                    color="fontMain"
                    fontSize="12px"
                    >username/your-image:tagging (If you are use
                    tagging)</CFormHelperText
                  >
                </CGrid>
              </CFormControl>
              <CFormControl
                mb="20px"
                v-if="currentAppType.type === 'MOBILE' && form.siteUrl"
              >
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  mb="20px"
                  w="100%"
                >
                  <CFormLabel
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    d="flex"
                    alignItems="center"
                    >{{ currentAppType.form_label.packageName }}</CFormLabel
                  >
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontWeight="550"
                    fontSize="14px"
                  >
                    {{ mobileData.package ? mobileData.package : "Not Found" }}
                    <br /><strong>{{
                      mobileData.platform
                        ? "Framework detected as " +
                          mobileData.platform.toUpperCase()
                        : ""
                    }}</strong></CText
                  >
                </CGrid>
              </CFormControl>
              <CFormControl mb="20px" v-if="currentAppType.type === 'MOBILE'">
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  w="100%"
                >
                  <CFormLabel
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    d="flex"
                    alignItems="center"
                    >{{ currentAppType.form_label.driver }}</CFormLabel
                  >
                  <CRadioGroup v-model="form.driver">
                    <template
                      v-for="(r, i) in currentAppType.form_label.drivers || []"
                    >
                      <CRadio
                        :key="i"
                        :isDisabled="
                          !currentUser ||
                          isReachDeadline() ||
                          !isChallengeBegin()
                        "
                        :value="r.value"
                      >
                        {{ r.name }}
                      </CRadio>
                    </template>
                  </CRadioGroup>
                  <!-- <CSelect
                    v-model="form.driver"
                    :disabled="isReachDeadline()"
                    :placeholder="currentAppType.form_label.driverPlaceholder"
                  >
                    <template
                      v-for="(r, i) in currentAppType.form_label.drivers || []"
                    >
                      <option
                        :key="i"
                        :value="r.value"
                        v-if="r.availableAtAppId.includes(currentAppType.id)"
                      >
                        {{ r.name }}
                      </option>
                    </template>
                  </CSelect> -->
                </CGrid>
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="6"
                  w="100%"
                  v-if="statusForm.driver"
                >
                  <CBox></CBox>
                  <CFormHelperText
                    v-if="!$v.form.driver.required"
                    color="red"
                    fontSize="12px"
                    >Framework wajib diisi</CFormHelperText
                  >
                </CGrid>
              </CFormControl>
              <CFormControl
                mb="20px"
                v-if="
                  currentAppType.type === 'BE' &&
                  currentAppType.databases.length
                "
              >
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  w="100%"
                >
                  <CFormLabel
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    d="flex"
                    alignItems="center"
                    >{{ currentAppType.form_label.database }}</CFormLabel
                  >
                  <CRadioGroup v-model="currentAppType.databases[0].value">
                    <template v-for="(r, i) in currentAppType.databases || []">
                      <CRadio
                        :key="i"
                        :isDisabled="
                          !currentUser ||
                          isReachDeadline() ||
                          !isChallengeBegin()
                        "
                        :value="r.value"
                      >
                        {{ r.name }}
                      </CRadio>
                    </template>
                  </CRadioGroup>
                </CGrid>
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="6"
                  w="100%"
                  v-if="statusForm.database"
                >
                  <CBox></CBox>
                  <CFormHelperText
                    v-if="!$v.form.database.required"
                    color="red"
                    fontSize="12px"
                    >Database wajib diisi</CFormHelperText
                  >
                </CGrid>
              </CFormControl>
              <CFormControl mb="20px">
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  w="100%"
                >
                  <CFormLabel
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    d="flex"
                    alignItems="center"
                    >{{ currentAppType.form_label.hashtag }}</CFormLabel
                  >
                  <div>
                    <Select2
                      ref="hashtagInput"
                      :disabled="
                        !currentUser || isReachDeadline() || !isChallengeBegin()
                      "
                      v-model="form.hashtags"
                      :placeholder="
                        currentAppType.form_label.hashtagPlaceholder
                      "
                      :settings="hashtagOptions()"
                    />
                  </div>
                </CGrid>
              </CFormControl>
              <CFormControl v-if="currentAppType.expired_date" mb="20px">
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  w="100%"
                >
                  <CFormLabel
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    d="flex"
                    alignItems="center"
                    >{{ currentAppType.form_label.deadline }}</CFormLabel
                  >
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontWeight="550"
                    fontSize="14px"
                  >
                    {{
                      formatExpiredDate(
                        currentAppType.view_type,
                        currentAppType.expired_date
                      )
                    }}</CText
                  >
                </CGrid>
              </CFormControl>
            </CBox>
            <CBox
              d="flex"
              justifyContent="flex-end"
              :m="[
                '20px 20px 13px 20px',
                '20px 20px 13px 20px',
                '20px 20px 20px 20px',
                '20px 20px 20px 20px',
              ]"
              pb="20px"
            >
              <CButton
                variant="outline"
                backgroundColor="#fff"
                mr="2"
                color="main"
                borderColor="main"
                fontSize="14px"
                @click="setClipboard()"
              >
                <CImage
                  :src="require('@/assets/icon/copy.svg')"
                  w="18px"
                  h="18px"
                  mr="2"
                />
                Copy Challenge URL
              </CButton>
              <ButtonBlue
                px="30px"
                :disabled="
                  // isInactive() ||
                  !isJoinedOfficialClub ||
                  !isEndedCountdown ||
                  !currentUser ||
                  isReachDeadline() ||
                  !isChallengeBegin() ||
                  (isUploading && isSubmitPressed) ||
                  isNotStarted() ||
                  isTokenExpired()
                "
                @handleClick="() => (isSubmitPressed = true)"
                :label="
                  !isChallengeBegin()
                    ? days + ':' + hours + ':' + minutes + ':' + seconds
                    : ['LOGIC', 'PLAYGROUND'].includes(currentAppType.type) &&
                      isChallengeBegin()
                    ? 'Start'
                    : isUploading && isSubmitPressed
                    ? 'Please Wait...'
                    : 'Submit'
                "
              />
            </CBox>
          </CBox>
          <CBox
            v-if="
              currentAppType?.settings?.clubs &&
              currentAppType?.settings?.clubs.length > 0
            "
            borderRadius="10px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            mx="auto"
            bg="white"
            py="20px"
          >
            <CBox
              v-if="!isJoinedOfficialClub && !isLoading"
              px="10px"
              py="10px"
              borderRadius="4px"
              bgColor="bgFailedState"
              my="8px"
            >
              <CText
                display="inline"
                fontFamily="Montserrat"
                color="failedColor"
                fontWeight="bold"
                fontSize="14px"
                >{{
                  !isJoinedOfficialClub &&
                  !isLoading &&
                  "Please join main club to start the challenge"
                }}</CText
              >
            </CBox>
            <CardClub :id="parseInt(currentAppType.id)" />
          </CBox>
          <CBox
            v-if="currentUser"
            mt="20px"
            bg="white"
            minH="100px"
            borderRadius="10px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox py="20px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="bold"
                color="fontMain"
              >
                Your Submission History
              </CText>
              <CGrid
                :templateColumns="[
                  '6fr 6fr',
                  '6fr 6fr',
                  '6fr 6fr',
                  '2fr 2fr 2fr 3fr 2fr',
                ]"
                :gap="4"
                h="inherit"
                pt="3"
              >
                <CBox
                  border="1px solid #E2E8F0"
                  W="100%"
                  borderRadius="8px"
                  px="3"
                  py="2"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight="500"
                    display="block"
                    color="#9e9e9e"
                    mb="1"
                  >
                    Total Submit
                  </CText>
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="600"
                    color="fontMain"
                  >
                    {{ currentTestSummary.total_submission || 0 }}
                  </CText>
                </CBox>
                <CBox
                  border="1px solid #E2E8F0"
                  W="100%"
                  borderRadius="8px"
                  px="3"
                  py="2"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight="500"
                    display="block"
                    color="#9e9e9e"
                    mb="1"
                  >
                    Current Rank
                  </CText>
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="600"
                    color="fontMain"
                  >
                    <CImage
                      :src="require('@/assets/icon/ic-first-medal.png')"
                      v-if="currentTestSummary.current_rank == 1"
                    />
                    <CImage
                      :src="require('@/assets/icon/ic-second-medal.png')"
                      v-if="currentTestSummary.current_rank == 2"
                    />
                    <CImage
                      :src="require('@/assets/icon/ic-third-medal.png')"
                      v-if="currentTestSummary.current_rank == 3"
                    />
                    <template
                      v-if="
                        currentTestSummary.current_rank > 3 ||
                        !currentTestSummary.current_rank
                      "
                    >
                      {{ currentTestSummary.current_rank || 0 }}
                    </template>
                  </CText>
                </CBox>
                <CBox
                  border="1px solid #E2E8F0"
                  W="100%"
                  borderRadius="8px"
                  px="3"
                  py="2"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight="500"
                    display="block"
                    color="#9e9e9e"
                    mb="1"
                  >
                    Best Score
                  </CText>
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="600"
                    color="fontMain"
                  >
                    {{ currentTestSummary.score || 0 }}
                  </CText>
                </CBox>
                <CBox
                  border="1px solid #E2E8F0"
                  W="100%"
                  borderRadius="8px"
                  px="3"
                  py="2"
                >
                  <CText
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight="500"
                    display="block"
                    color="#9e9e9e"
                    mb="1"
                  >
                    Best Response Time
                  </CText>
                  <CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    fontWeight="600"
                    color="fontMain"
                  >
                    {{
                      currentTestSummary.response_time
                        ? currentTestSummary.response_time / 1000
                        : 0
                    }}
                    ms
                  </CText>
                  <!-- <CText
                    @click.native="$router.push({ name: 'Competition Metrics', params: { slug: currentAppType.slug } })"
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight="700"
                    color="activeMenu"
                    class="pointer"
                    mr="3">
                      Metrics
                  </CText> -->
                </CBox>
              </CGrid>
            </CBox>
            <CDivider />
            <CBox
              v-if="currentAppType.type === 'BE' && tests.length !== 0"
              w="700px"
              px="28px"
            >
              <Draggable direction="row">
                <CBox
                  flex="0 0 auto"
                  maxW="150px"
                  h="40px"
                  d="flex"
                  alignItems="center"
                  mb="12px"
                  px="6px"
                  borderRadius="12px"
                  border="1px solid"
                  :borderColor="
                    defaultMetricsLabel === -1 ? 'activeMenu' : 'bgMain'
                  "
                  :fontWeight="defaultMetricsLabel === -1 ? 'bold' : '400'"
                  cursor="pointer"
                  @click.native="changeLabelMetrics(-1, 'Default Result')"
                  ><CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    :color="
                      defaultMetricsLabel === -1 ? 'activeMenu' : 'fontMain'
                    "
                    >Default Result</CText
                  ></CBox
                >
                <CBox
                  v-for="(metric, i) of currentTestMetrics"
                  :key="i"
                  flex="0 0 auto"
                  minW="150px"
                  h="40px"
                  d="flex"
                  alignItems="center"
                  mb="12px"
                  px="6px"
                  borderRadius="12px"
                  border="1px solid"
                  :borderColor="
                    defaultMetricsLabel === i ? 'activeMenu' : 'bgMain'
                  "
                  :fontWeight="defaultMetricsLabel === i ? 'bold' : '400'"
                  cursor="pointer"
                  @click.native="changeLabelMetrics(i, metric.label)"
                  ><CText
                    fontFamily="Montserrat"
                    fontSize="14px"
                    :color="
                      defaultMetricsLabel === i ? 'activeMenu' : 'fontMain'
                    "
                    >{{ metric.label }}</CText
                  >
                </CBox>
              </Draggable>
            </CBox>
            <CBox mt="2">
              <JobResultItem
                v-for="(test, i) in tests"
                :key="i"
                :test="test"
                :currentAppType="currentAppType"
                :appTypeId="appTypeId"
                :checkEmpObject="checkEmpObject"
                :metricsLabels="currentMetricsLabelNames"
                :defaultMetricsHeader="defaultMetricsHeader"
              />

              <CBox
                mt="20px"
                align="center"
                v-if="meta.max_current_page < meta.total"
              >
                <CText
                  v-if="!isLoadingTest"
                  fontFamily="Montserrat"
                  fontSize="16px"
                  fontWeight="500"
                  color="activeMenu"
                  class="pointer"
                  @click.native="nextData()"
                  >Load More ...</CText
                >
                <CSpinner v-else />
              </CBox>
              <CBox h="30px" />
            </CBox>
          </CBox>
        </CBox>
        <CBox
          v-if="currentAppType.view_type != 'RECRUITMENT'"
          pb="2em"
          min-w="100px"
          w="100%"
          max-w="100%"
        >
          <JobFilter
            v-if="currentAppType.settings.jobKeyword"
            :keyword="currentAppType.settings.jobKeyword"
          />
          <LeaderboardNew
            v-if="currentAppType.id && !currentAppType.review_job_target"
            :id="parseInt(currentAppType.id)"
            :slug="currentAppType.slug"
            :track="$route.params.track"
            :default_leaderboard="currentAppType.default_leaderboard"
            :arena_id="arena"
          />
          <!-- <LeaderboardFilters
            v-if="currentUser && currentAppType.id"
            :id="parseInt(currentAppType.id)"
          /> -->
          <Statistic
            v-if="currentAppType.review_job_target"
            :id="parseInt(currentAppType.id)"
            :slug="currentAppType.slug"
            :track="$route.params.track"
            :default_leaderboard="currentAppType.default_leaderboard"
            :arena_id="arena"
          />
          <ListPreviewClub />
        </CBox>
      </CGrid>
    </CBox>
  </CBox>
</template>

<script>
import {
  CBox,
  CGrid,
  CText,
  CStack,
  CImage,
  CFormControl,
  CFormLabel,
  CInput,
  CRadioGroup,
  CRadio,
} from "@chakra-ui/vue";
import ButtonBlue from "@/components/button/ButtonBlue.vue";
import Draggable from "@/components/Draggable.vue";
import JobResultItem from "@/components/JobResultItem.vue";
import { GET_APP_TYPE, GET_APP_METRICS } from "@/store/app-types.module";
import { GET_APP_TYPE_VIEW } from "@/store/app-type-view.module";
import { GET_TRACK_DETAIL } from "@/store/track.module";
import { GET_TEST_SCENARIOS } from "@/store/test-scenarios.module";
import { GET_CLUBS } from "@/store/club.module";
import {
  POST_TEST,
  GET_TESTS,
  GET_TEST_SUMMARY,
  UPLOAD_APP,
  RESET_PROGRESS_BAR,
} from "@/store/test.module";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";
import moment from "moment-timezone";
import "moment-timezone";
import axios from "axios";
import { required, url } from "vuelidate/lib/validators";
import LeaderboardNew from "@/components/LeaderboardNew.vue";
import JobFilter from "@/components/JobFilter.vue";
// import LeaderboardFilters from "@/components/LeaderboardFilters.vue";
import _ from "lodash";
import config from "@/ConfigProvider";
import ApiService from "@/store/api.service";
import ListPreviewClub from "@/components/list/ListPreviewClub.vue";
import CardClub from "@/components/card/CardClub.vue";
import Statistic from "@/components/Statistic.vue"

export default {
  metaInfo() {
    const appName = this.currentAppType.name;
    return {
      title: this.currentAppType.name
        ? this.capitalizeFirstLetter(appName)
        : "",
      titleTemplate: this.currentAppType.name ? "Devcode - %s" : "Devcode",
    };
  },
  // metaInfo: {
  //   title: this.currentSlug,
  //   titleTemplate: "Devcode - %s"
  // },
  name: "Competition",
  components: {
    CBox,
    CGrid,
    CText,
    CStack,
    CImage,
    CFormControl,
    CFormLabel,
    CInput,
    CRadioGroup,
    CRadio,
    ButtonBlue,
    Loading,
    // Loading2,
    LeaderboardNew,
    JobFilter,
    Select2,
    // LeaderboardFilters,
    Draggable,
    JobResultItem,
    ListPreviewClub,
    CardClub,
    Statistic
  },
  mixins: [validationMixin],
  data() {
    return {
      baseUrl: config.value("DEVCODE_WEB_URL"),
      isLoading: false,
      isUploading: false,
      isCancelUpload: false,
      isSubmitPressed: false,
      isReadySubmit: true,
      uploadRequest: null,
      mobileData: {},
      arena: null,
      track: null,
      form: {
        siteName: "",
        siteUrl: "",
        driver: "",
        hashtags: [],
        database: "mysql",
        sessionId: "",
      },
      statusForm: {
        siteName: false,
        siteUrl: false,
        driver: false,
        database: false,
      },
      tests: [],
      meta: {},
      isLoadingTest: false,
      testScenarios: [],
      page: 1,
      limit: 10,
      selectedTagList: [],
      defaultTimezone: config.value("DEFAULT_TIMEZONE"),
      appTypeView: {},
      defaultMetricsLabel: -1,
      defaultMetricsHeader: "Default Result",
      shareChallengeUrl: "",
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
      isEndedCountdown: false,
      isJoinedOfficialClub: false,
      dataFetched: false,
    };
  },
  watch: {
    officialClubs() {
      this.getData();
      this.isJoinedOfficialClubHandler();
    },
    isSubmitPressed(val) {
      if (val && !this.isUploading) this.onSubmit();
    },
    isUploading(val) {
      if (!val && this.isSubmitPressed) this.onSubmit();
    },
    formHashtags() {
      this.setHashtagAlphaLetterOnly();
    },
    tests: function (current) {
      // set last hashtags field by current submission hashtags from history
      let me = this;
      if (me.form.hashtags.length < 1 && current.length > 0) {
        const { hashtags } = current[0];
        hashtags.map(({ name }) => {
          me.$refs.hashtagInput.select2
            .append(new Option(name, name, false, false))
            .trigger("change");
          me.form.hashtags.push(name);
        });
      }
    },
  },
  created() {
    const dataLayer = window.dataLayer || [];
    const gtag = () => dataLayer.push(arguments);
    gtag("js", new Date());
    gtag("config", "G-F2KHZP9RQ6");
    if (!this.dataFetched) {
      this.getData();
      this.dataFetched = true;
    }
  },
  mounted() {
    // if (this.appTypeId == 0) {
    //   this.$router.push({ name: "404" });
    //   return;
    // }
    // console.log(this.$route.query?.hashtag);
    // if (this.$route.query?.hashtag) {}
    // prevent hashtag input only alpha letters characters
    this.setHashtagAlphaLetterOnly();

    // generate session ID if not exists
    this.generateSessionId();
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  },
  computed: {
    officialClubs() {
      return this.$store.getters.officialClubs || [];
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    currentTrack() {
      return this.$store.getters.currentTrack;
    },
    currentTestScenarios() {
      return this.$store.getters.currentTestScenarios;
    },
    currentTestMetrics() {
      return this.$store.getters.currentAppMetrics;
    },
    currentMetricsLabelNames() {
      return this.defaultMetricsLabel >= 0
        ? this.$store.getters.currentAppMetrics[
            this.defaultMetricsLabel
          ].name.split(",")
        : [];
    },
    appTypeId() {
      return this.currentAppType.id;
    },
    formValidation() {
      const validation = {
        siteName: { required },
        siteUrl: { required },
      };
      if (this.currentAppType.type === "FE") {
        Object.assign(validation.siteUrl, { url });
      }
      if (this.currentAppType.type === "MOBILE") {
        Object.assign(validation, { driver: { required } });
      }

      if (this.currentAppType.type === "BE") {
        Object.assign(validation, { database: { required } });
      }
      // } else if (this.currentAppType.type === "BE") {
      //   Object.assign(validation.siteUrl, {
      //     url: function (value) {
      //       const url =
      //         /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
      //           value
      //         );
      //       return url;
      //     },
      //   });
      // }
      return validation;
    },
    currentProgressBar() {
      return this.$store.getters.currentProgressBar;
    },
    currentTestSummary() {
      return this.$store.getters.currentTestSummary;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    formHashtags() {
      return this.form.hashtags;
    },
  },
  methods: {
    isJoinedOfficialClubHandler() {
      if (!this.officialClubs.data) {
        this.isJoinedOfficialClub = true; // Kembalikan false jika data tidak ada
      } else {
        // Cek apakah ada elemen dalam this.officialClubs.data yang is_joined: 1
        this.isJoinedOfficialClub = this.officialClubs.data.some(
          (club) => club.is_joined === 1
        );
      }
    },
    updateRemaining(distance) {
      this.days = this.formatTime(Math.floor(distance / (1000 * 60 * 60 * 24)));
      this.hours = this.formatTime(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      this.minutes = this.formatTime(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      this.seconds = this.formatTime(
        Math.floor((distance % (1000 * 60)) / 1000)
      );
    },
    tick() {
      if (!this.isLoading) {
        var endDate = Date.parse(this.currentAppType.start_date);
        const currentTime = new Date();
        const distance = Math.max(endDate - currentTime, 0);
        this.updateRemaining(distance);

        if (distance === 0) {
          clearInterval(this.timer);
          this.isEndedCountdown = true;
        }
      }
    },
    formatTime(time) {
      return (time < 10 ? "0" : "") + time;
    },
    // isInactive() {
    //   const active_status = this.currentAppType.active_status;
    //   return active_status === "inactive";
    // },
    applyFontSizes(htmlContent) {
      // Adding classes to headings
      const modifiedContent = htmlContent
        .replace(/<h1/g, '<h1 class="h1-custom"')
        .replace(/<h2/g, '<h2 class="h2-custom"')
        .replace(/<h3/g, '<h3 class="h3-custom"');
      return modifiedContent;
    },
    extractVideoId(youtubeUrl) {
      const videoId = youtubeUrl.split("?v=")[1];
      return videoId || "";
    },
    redirectToSignIn() {
      window.location.href =
        config.value("GETHIRED_WEB_URL") +
        `/signin?next=${window.location.href}`;
    },
    setHashtagAlphaLetterOnly() {
      setTimeout(() => {
        this.$refs.hashtagInput.select2
          .data("select2")
          .selection.$search.on("keypress", function (e) {
            return (
              (e.charCode > 64 && e.charCode < 91) ||
              (e.charCode > 96 && e.charCode < 123) ||
              (e.charCode !== 32 && e.charCode !== 35)
            );
          });
      }, 50);
    },
    setClipboard() {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = window.location;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: "Copied",
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: false,
      });
    },
    changeLabelMetrics(index, header) {
      this.defaultMetricsLabel = index;
      this.defaultMetricsHeader = header;
    },
    hashtagOptions() {
      let me = this;
      return {
        multiple: true,
        templateResult: function ({ text }) {
          return `#${text}`;
        },
        escapeMarkup: function (markup) {
          return markup;
        },
        language: {
          noResults: function () {
            const ref = me.$refs.hashtagInput;
            const val = ref.select2.data("select2").selection.$search[0].value;
            const formattedVal = val.includes("#") ? val.slice(1) : val;

            if (formattedVal && formattedVal.trim().length >= 3) {
              const uniqId = Math.random().toString(36).slice(2);

              const removeDuplicateOption = function (value) {
                const options = document.querySelectorAll(
                  `option[value="${value}"]`
                );
                Array.prototype.map.call(options, (option) => option.remove());
              };

              // custom event listener for add new tag if not found
              const addNewItem = function () {
                const text = formattedVal.trim();

                removeDuplicateOption(text);

                ref.select2
                  .append(new Option(text, text, false, false))
                  .trigger("change");
                ref.select2
                  .data("select2")
                  .selection.$search.val("")
                  .trigger("keyup")
                  .trigger("click");

                me.form.hashtags = me.form.hashtags.filter(
                  (hashtag) => hashtag !== text
                );

                me.form.hashtags.push(text);
              };

              setTimeout(() => {
                let el = document.querySelector(".add-new-select2" + uniqId);
                if (el) {
                  el.removeEventListener("click", addNewItem);
                  el.addEventListener("click", addNewItem);
                }
              }, 100);

              return `<span>
                <span style='color: grey;'>Tambahkan tag </span>
                <span style='color: #16abf8; text-decoration: underline;'
                class='pointer add-new-select2${uniqId}'>#${formattedVal}</span>
              </span>`;
            }
            return "Not Found";
          },
        },
        ajax: {
          transport: function (params, success, failure) {
            ApiService.get("/hashtags", {
              name: params?.data?.q,
              page: 1,
              limit: 10,
            })
              .then(({ data }) => success(data))
              .catch((err) => failure(err));
          },
          processResults: function (data) {
            return {
              results:
                data?.data.map((r) => {
                  return { id: r.name, text: r.name };
                }) ?? [],
            };
          },
        },
      };
    },
    openApp(url) {
      window.open(url, "_blank");
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    resetProgressBar() {
      this.$store.dispatch(RESET_PROGRESS_BAR, { progressName: "progressApp" });
    },
    cancelUpload() {
      this.isCancelUpload = true;
      this.request.cancel();
      this.mobileData = {};
      this.form.siteUrl = "";
    },
    dispatchUploadProcess(event) {
      if (this.request) this.cancelUpload();
      this.uploadProcess(event);
    },
    async uploadProcess(event) {
      const axiosSource = axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      try {
        this.isUploading = true;
        const upload = await this.$store.dispatch(UPLOAD_APP, {
          data: event.target.files[0],
          progressName: "progressApp",
          cancelToken: axiosSource,
        });
        this.form.siteUrl = "https://" + upload.location;
        this.mobileData = upload.apk;
        this.isUploading = false;
      } catch (err) {
        this.isUploading = false;
        let errData = err.message;
        if (err.response && err.response.data && err.response.data.message)
          errData = err.response.data.message;

        if (!this.isCancelUpload) {
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }

        this.resetProgressBar();
        this.isCancelUpload = false;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    nextData() {
      this.page = this.page + 1;
      this.getTests(true);
    },
    checkEmpObject(obj) {
      return _.isEmpty(obj);
    },
    changeStatus(name) {
      this.statusForm[name] = true;
    },
    async getData() {
      let appType;
      try {
        if (this.$route.params.track) {
          const params = {
            slug: this.$route.params.track,
          };

          await this.$store.dispatch(GET_TRACK_DETAIL, params);
        }

        this.isLoading = true;
        this.arena = this.$route.params?.arena;
        this.track = this.$route.params?.track;

        appType = await this.$store.dispatch(
          GET_APP_TYPE,
          this.$route.params.slug
        );

        // redirect to profile page, if this challenge is unpublished
        if (
          appType.publish_status === "unpublish" ||
          Object.keys(appType).length === 0
        ) {
          const { emp_id } = this.currentUser;
          Swal.fire({
            title: "Oops . .",
            text: "It seems this assessment is no longer available. Explore our range of other challenges to continue learning and growing!",
            confirmButtonClass: "btn btn-secondary",
            confirmButtonText: "Ok",
            heightAuto: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = emp_id
                ? `/profile/${emp_id}`
                : `${config.value("GETHIRED_WEB_URL")}/employee/profile`;
            }
          });
        }

        // redirect to arena page, if this challenge has arena
        if (appType.isHaveArena && !this.arena) {
          Swal.fire({
            text: "Pilih Arena yang akan diikuti pada challenge ini",
            heightAuto: true,
          });
          this.$router.push({
            name: "Arena",
            params: { slug: this.$route.params.slug },
          });
        }

        await this.getAppTypeView(this.appTypeId);
        await this.getTests();
        await this.$store.dispatch(
          GET_APP_METRICS,
          this.currentAppType.app_name
        );
        await this.$store.dispatch(GET_TEST_SCENARIOS, {
          appId: this.appTypeId,
        });
        this.isJoinedOfficialClubHandler();
        const params = {
          data: {
            limit: 5,
            sortBy: "total_request",
            sort: "DESC",
            myClub: true,
          },
        };
        await this.$store.dispatch(GET_CLUBS, params);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (appType.view_type == "RECRUITMENT" && !this.appTypeView?.token) {
          Swal.fire({
            text: "Maaf, anda perlu login di GetHired.id terlebih dahulu. Akses halaman ini kembali setelah anda melakukan Login pada GetHired.id.",
            heightAuto: true,
            willClose: () => {
              window.location.href =
                config.value("GETHIRED_WEB_URL") + "/employee/profile";
            },
          });
        }
        // console.log(err);
        // Swal.fire({
        //   title: "Hi ..",
        //   text: "Selamat datang di Devcode perhatikan requirement dan detail test scenario yang ada",
        //   heightAuto: true,
        //   willClose: () => {
        //     this.reloadData();
        //   },
        // });
      }
      this.shareChallengeUrl =
        this.baseUrl + "/challenge/share/" + this.$route.params.slug;
    },
    reloadData() {
      this.getData();
    },
    formatToLocalDate(date) {
      return (
        new Date(date).toLocaleString("id-ID", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: this.defaultTimezone,
        }) + " WIB"
      );
    },
    formatExpiredDate(type, date) {
      const endDate = moment(date).tz(this.defaultTimezone);
      // const maximumSubmitDate = moment(this.appTypeView.expired_date).tz(
      //   this.defaultTimezone
      // );

      if (type === "COMPETITION") {
        return moment(endDate).format("DD MMMM YYYY HH:mm z");
      } else {
        return this.formatToLocalDate(this.appTypeView.expired_date);
        // return  moment(maximumSubmitDate)
        //   .set({ h: 24, m: 0 })
        //   .format("DD MMMM YYYY HH:mm z");
      }
    },
    formatStartDate(date) {
      return moment(date).tz(this.defaultTimezone);
    },
    isChallengeBegin() {
      let result = false;

      if (this.currentAppType.start_date) {
        const startDate = this.formatStartDate(this.currentAppType.start_date);

        const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
        const timeZone = moment.tz.guess();
        const convertedTime = moment
          .tz(currentTime, timeZone)
          .tz("Asia/Jakarta")
          .format("YYYY-MM-DD HH:mm:ss");

        const diffSeconds = moment(startDate, "DD MMMM YYYY HH:mm").diff(
          convertedTime,
          "seconds"
        );

        diffSeconds <= 1 ? (result = true) : (result = false);
        return result;
      }
      return result;
    },

    isReachDeadline() {
      let result = false;

      if (
        this.currentAppType.expired_date ||
        this.currentAppType.participant_duration
      ) {
        const expiredDate = this.formatExpiredDate(
          this.currentAppType.view_type,
          this.currentAppType.expired_date
        );

        const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
        const timeZone = moment.tz.guess();
        const convertedTime = moment
          .tz(currentTime, timeZone)
          .tz("Asia/Jakarta")
          .format("YYYY-MM-DD HH:mm:ss");
        const diffMinutes = moment(expiredDate, "DD MMMM YYYY HH:mm z").diff(
          convertedTime,
          "minutes"
        );

        diffMinutes <= 0 ? (result = true) : (result = false);
        return result;
      }

      return result;
    },
    isNotStarted() {
      let result = false;
      if (this.currentAppType.view_type === "RECRUITMENT") {
        const start_date = this.appTypeView.created_at;
        result = new Date(start_date) > new Date();
      }

      return result;
    },
    isTokenExpired() {
      let result = false;
      if (this.currentAppType.view_type === "RECRUITMENT") {
        const expired_date = this.appTypeView.expired_date;
        result = new Date() > new Date(expired_date);
        console.log(new Date());
        console.log(this.appTypeView.expired_date);
      }

      return result;
    },
    async getTests(showError = false) {
      try {
        this.isLoadingTest = true;
        await Promise.all([
          this.$store.dispatch(GET_TEST_SUMMARY, this.appTypeId),
          this.$store.dispatch(GET_TESTS, {
            appTypeId: this.appTypeId,
            page: this.page,
            limit: this.limit,
          }),
        ]);
        const tests = this.$store.getters.currentTests;

        this.isLoadingTest = false;
        this.tests = tests.data;
        this.meta = tests.meta;
      } catch (err) {
        this.isLoadingTest = false;
        if (showError) {
          Swal.fire({
            title: "Oops . .",
            text: err.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      }
    },
    async getAppTypeView(appTypeId) {
      try {
        const res = await this.$store.dispatch(GET_APP_TYPE_VIEW, {
          app_type_id: appTypeId,
          arena: this.arena,
          track: this.track,
        });

        this.appTypeView = res;
      } catch (err) {
        const code = err.response.status;
        const viewType = this.currentAppType.view_type;
        if (code === 403) {
          Swal.fire({
            text: "Maaf, Anda tidak memiliki akses",
            heightAuto: true,
            willClose: () => {
              window.location.href = "/";
            },
          });
        } else if (viewType == "RECRUITMENT" && code === 401) {
          Swal.fire({
            text: "Maaf, anda perlu login di GetHired.id terlebih dahulu. Akses halaman ini kembali setelah anda melakukan Login pada GetHired.id",
            heightAuto: true,
            willClose: () => {
              window.location.href =
                config.value("GETHIRED_WEB_URL") + "/employee/profile";
            },
          });
        }
      }
    },
    mobileAppValidation() {
      if (this.currentAppType.type === "MOBILE") {
        if (this.form.driver === "flutter" && !this.mobileData.debuggable) {
          Swal.fire({
            title: "Oops . .",
            text: this.currentAppType.alertMessage.notDebuggable,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.isLoading = false;
          this.isReadySubmit = false;
          this.isSubmitPressed = false;
        } else if (this.mobileData.usesSdk.targetSdkVersion < 30) {
          Swal.fire({
            title: "Oops . .",
            text: this.currentAppType.alertMessage.belowtargetSdk,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.isLoading = false;
          this.isReadySubmit = false;
          this.isSubmitPressed = false;
        } else {
          this.isReadySubmit = true;
        }
      }
    },
    generateUniqueId() {
      // Generate a random string of characters
      const randomString =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      // Create a timestamp to make the ID more unique
      const timestamp = new Date().getTime();

      // Combine random string and timestamp to create a unique ID
      const uniqueId = randomString + timestamp;

      return uniqueId;
    },
    setCookie(name, value, expirationDays) {
      const date = new Date();
      const days = expirationDays * 24 * 60 * 60 * 1000;
      date.setTime(date.getTime() + days);
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    },
    getCookie(name) {
      const regex = new RegExp(`(^| )${name}=([^;]+)`);
      const match = document.cookie.match(regex);
      if (match) {
        return match[2];
      }
      return null;
    },
    generateSessionId() {
      const sessionName = "session_id";
      let sessionId = this.getCookie(sessionName);
      if (!sessionId) {
        sessionId = this.generateUniqueId();
        this.setCookie(sessionName, sessionId, 3);
      }
    },
    onSubmit() {
      if (["LOGIC", "PLAYGROUND"].includes(this.currentAppType.type)) {
        const token =
          this.$router.history.current.query?.token || this.appTypeView.token;
        if (token) {
          const logicUrl = config.value("DEVCODE_LOGIC_TEST_URL");
          if (this.currentAppType.type === "LOGIC") {
            const url = `${logicUrl}/playground/intro/${token}`;
            window.open(url, "_blank");
          } else if (this.currentAppType.type === "PLAYGROUND") {
            const url = `${logicUrl}/playground/${token}`;
            window.open(url, "_blank");
          }
        } else {
          Swal.fire({
            title: "Oops . .",
            text: "You need token to start logic test challenge",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      }

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        for (const d in this.statusForm) {
          this.statusForm[d] = true;
        }

        this.isSubmitPressed = false;
        return;
      }

      this.isLoading = true;
      this.mobileAppValidation();

      const formUsed = {
        ...this.form,
        userName: "dummy",
        appId: this.appTypeId.toString(),
        type: this.currentAppType.type,
      };

      const sessionId = this.getCookie("session_id");
      if (sessionId) {
        formUsed.sessionId = sessionId;
      }

      if (this.isReadySubmit) {
        this.$store
          .dispatch(POST_TEST, formUsed)
          .then((data) => {
            this.form.siteName = "";
            this.form.siteUrl = "";
            for (const d in this.statusForm) {
              this.statusForm[d] = false;
            }

            this.isLoading = false;
            this.resetProgressBar();
            this.$router.push({
              name: "Detail Job",
              params: { id: data.test_id },
            });

            (async () => {
              // onesignal show prompt notification
              this.$OneSignal.init({
                appId: config.value("ONESIGNAL_APP_ID"),
                safari_web_id: config.value("ONESIGNAL_SAFARI_ID"),
                notifyButton: {
                  enable: true,
                },
                allowLocalhostAsSecureOrigin: true,
              });
              await this.$OneSignal.showNativePrompt();
              if (await this.$OneSignal.isPushNotificationsEnabled()) {
                const { emp_id } = this.currentUser;
                if (emp_id) {
                  this.$OneSignal.setExternalUserId(`emp/${emp_id}`);
                } else {
                  alert("Failed to set external user id for push notification");
                }
              } else {
                this.$OneSignal.removeExternalUserId();
              }
            })();

            // Swal.fire({
            //   title: "OK",
            //   text: "Berhasil submit test",
            //   icon: "success",
            //   confirmButtonClass: "btn btn-secondary",
            //   heightAuto: false,
            // });
            // this.getTests();
          })
          .catch((err) => {
            this.isLoading = false;
            let errData = err.message;
            if (err.response && err.response.data && err.response.data.message)
              errData = err.response.data.message;
            Swal.fire({
              title: "Oops . .",
              text: errData,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          });
      }

      this.isSubmitPressed = false;
    },
  },
  validations() {
    return {
      form: this.formValidation,
    };
  },
};
</script>

<style>
.h1-custom {
  font-size: 24px;
  /* Your custom font size for h1 */
}
.h2-custom {
  font-size: 20px;
  /* Your custom font size for h2 */
}
.h3-custom {
  font-size: 16px;
  /* Your custom font size for h3 */
}
</style>
