import { render, staticRenderFns } from "./Competition.vue?vue&type=template&id=27e0272c"
import script from "./Competition.vue?vue&type=script&lang=js"
export * from "./Competition.vue?vue&type=script&lang=js"
import style0 from "./Competition.vue?vue&type=style&index=0&id=27e0272c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CLink: require('@chakra-ui/vue').CLink, CBox: require('@chakra-ui/vue').CBox, CImage: require('@chakra-ui/vue').CImage, CStack: require('@chakra-ui/vue').CStack, CDivider: require('@chakra-ui/vue').CDivider, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CIcon: require('@chakra-ui/vue').CIcon, CCollapse: require('@chakra-ui/vue').CCollapse, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CGrid: require('@chakra-ui/vue').CGrid, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl, CProgress: require('@chakra-ui/vue').CProgress, CRadio: require('@chakra-ui/vue').CRadio, CRadioGroup: require('@chakra-ui/vue').CRadioGroup, CButton: require('@chakra-ui/vue').CButton, CSpinner: require('@chakra-ui/vue').CSpinner})
