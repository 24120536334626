<template>
  <CBox>
    <CBox
      bg="white"
      borderRadius="5px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CFlex py="20px" mx="20px" alignItems="center" justifyContent="start">
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="16px"
          fontWeight="600"
          color="fontMain"
        >
          Statistik</CText
        >
        <!-- {{ currentStatistics.data }} -->
      </CFlex>
      <CFlex px="20px" alignItems="center" mb="12px">
        <CBox overflow="auto" pb="8px" d="flex">
          <CBox
            flex="0 0 auto"
            d="flex"
            alignItems="center"
            px="14px"
            py="2px"
            borderRadius="5px"
            fontSize="14px"
            border="1px solid"
            :borderColor="
              currentLeaderboardsActiveFilter.type !== 'club'
                ? 'activeMenu'
                : 'bgMain'
            "
            fontWeight="400"
            cursor="pointer"
            @click.native="resetLeaderboardHandler()"
            mr="6px"
          >
            <CText
              :color="
                currentLeaderboardsActiveFilter.type !== 'club'
                  ? 'activeMenu'
                  : 'fontMain'
              "
              >All</CText
            >
          </CBox>
          <CBox
            v-for="(v, i) in currentLeaderboardsFilters"
            :key="i"
            flex="0 0 auto"
            d="flex"
            alignItems="center"
            px="14px"
            py="2px"
            borderRadius="5px"
            fontSize="14px"
            border="1px solid"
            :borderColor="
              currentLeaderboardsActiveFilter.id === v.id
                ? 'activeMenu'
                : 'bgMain'
            "
            fontWeight="400"
            cursor="pointer"
            @click.native="
              filterHandler({
                type: 'club',
                id: v.id,
                name: v.name,
                slug: v.slug,
              })
            "
            mr="6px"
          >
            <CText
              :color="
                currentLeaderboardsActiveFilter.id === v.id
                  ? 'activeMenu'
                  : 'fontMain'
              "
              >{{ truncateText(v.name, 15) }}</CText
            >
          </CBox>
        </CBox>
      </CFlex>
      <CBox d="flex" justifyContent="center">
        <VueApexChart
          type="bar"
          :series="getSeries"
          :options="chartOptions"
          class="c-chart"
        />
      </CBox>
      <CBox
        :m="[
          '20px 20px 13px 20px',
          '20px 20px 13px 20px',
          '20px 20px 20px 20px',
          '20px 20px 20px 20px',
        ]"
        pb="30px"
        align="center"
      >
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="14px"
          fontWeight="600"
          color="activeMenu"
          class="pointer"
          @click.native="showmoreHandler()"
          >Show More
        </CText>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { CBox, CText } from "@chakra-ui/vue";
import {
  RESET_LEADERBOARDS,
  GET_LEADERBOARDS,
  GET_LEADERBOARDS_INITIAL,
  SET_LEADERBOARDS_LOADING,
  SET_LEADERBOARDS_ACTIVE_FILTER,
  GET_LEADERBOARDS_FILTERS,
} from "@/store/leaderboard-new.module";
import VueApexChart from "vue-apexcharts";
import { GET_STATISTICS } from "@/store/statistic.module.js";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    slug: {
      type: String,
      default: "",
    },
    track: {
      type: String,
      default: "",
    },
    default_leaderboard: {
      type: String,
      default: "",
    },
    arena_id: {
      type: String,
      default: "",
    },
  },
  components: {
    CBox,
    CText,
    VueApexChart,
  },
  watch: {
    id() {
      this.getData();
    },
    async officialClubs() {
      this.$store.commit(RESET_LEADERBOARDS);
      this.isLoading = true;
      this.getData();
    },
    currentLeaderboardsFilters() {},
  },
  data() {
    return {
      query: undefined,
      isOpen: false,
      isLoading: true,
      series: [
        {
          name: "Total User",
          data: [
            {
              x: "Designer",
              y: 10,
            },
            {
              x: "Frontend",
              y: 15,
            },
            {
              x: "Backend",
              y: 8,
            },
          ],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
      },
    };
  },
  mounted() {
    this.$store.commit(RESET_LEADERBOARDS);
    this.getData();
    this.getStatistics();
  },
  computed: {
    currentLeaderboardsActiveFilter() {
      return this.$store.getters.currentNewLeaderboardsActiveFilters;
    },
    currentLeaderboardsFilters() {
      // Mengambil data dari store
      const myclubs =
        this.$store.getters.currentNewLeaderboardsFilters.club || [];
      const officialclubs =
        this.$store.getters.currentNewLeaderboardsFilters["official club"] ||
        [];

      // Menggabungkan kedua array
      const combinedClubs = [...myclubs, ...officialclubs];

      // Menghapus duplikat berdasarkan ID, prioritas untuk data di `myclubs`
      const uniqueClubs = combinedClubs.reduce((acc, club) => {
        if (!acc[club.id]) {
          acc[club.id] = club;
        }
        return acc;
      }, {});

      // Pisahkan klub berdasarkan kondisi
      const competitiveJoined = [];
      const competitiveNotJoined = [];
      const nonCompetitiveJoined = [];

      Object.values(uniqueClubs).forEach((club) => {
        if (
          club.is_competition &&
          officialclubs.some((official) => official.id === club.id)
        ) {
          competitiveJoined.push(club);
        } else if (
          typeof club.is_competition === "undefined" &&
          typeof club.is_joined === "undefined"
        ) {
          competitiveNotJoined.push(club);
        } else {
          nonCompetitiveJoined.push(club);
        }
      });

      // Gabungkan array sesuai urutan prioritas
      const sortedClubs = [
        ...competitiveJoined,
        ...competitiveNotJoined,
        ...nonCompetitiveJoined,
      ];

      return sortedClubs;
    },
    getSeries() {
      const job = Object.keys(this.series);
      const seriesData = job.map((i) => ({
        x: this.truncateText(i, 15), // gunakan truncateText di sini
        y: this.series[i],
      }));

      return [
        {
          name: "Total User",
          data: seriesData,
        },
      ];
    },
  },
  methods: {
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    filterHandler(filter) {
      this.$store.commit(SET_LEADERBOARDS_ACTIVE_FILTER, {
        type: filter.type ? filter.type : "main",
        id: filter.id ? filter.id : null,
        name: filter?.name,
        slug: filter?.slug,
      });
      this.getData();
    },
    async getData() {
      try {
        this.$store.commit(SET_LEADERBOARDS_LOADING, true);

        await this.$store.dispatch(GET_LEADERBOARDS_FILTERS, {
          id: this.id,
        });

        if (
          this.isLoading &&
          this.currentLeaderboardsFilters &&
          this.currentLeaderboardsFilters.length > 0
        ) {
          this.$store.commit(SET_LEADERBOARDS_ACTIVE_FILTER, {
            type: "club",
            id: this.currentLeaderboardsFilters[0].id,
            name: this.currentLeaderboardsFilters[0].name,
            slug: this.currentLeaderboardsFilters[0].slug,
          });
        }

        if (this.currentLeaderboardsActiveFilter.type === "club") {
          this.query = {
            ["club"]: this.currentLeaderboardsActiveFilter.slug,
          };
        } else {
          this.query = undefined;
        }

        await this.$store.dispatch(GET_LEADERBOARDS_INITIAL, {
          id: this.id,
        });

        await this.$store.dispatch(GET_LEADERBOARDS, {
          id: this.id,
          rank: this.default_leaderboard,
        });

        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getStatistics() {
      try {
        const res = await this.$store.dispatch(GET_STATISTICS, {
          id: this.id,
        });
        this.series = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    resetLeaderboardHandler() {
      this.$store.commit(RESET_LEADERBOARDS);
      this.getData();
    },
    showmoreHandler() {
      const basePath = this.$route.params.track
        ? `/track/${this.$route.params.track}/leaderboard/${this.id}`
        : `/leaderboard/${this.id}`;

      const queryParams = `${
        this.currentLeaderboardsActiveFilter.type === "club"
          ? `?club=${this.currentLeaderboardsActiveFilter.slug}`
          : ""
      }&statistic=true`;

      const newPath = `${basePath}${queryParams}`;

      // Navigate to the new path and reload the page
      window.location.href = newPath;
    },
  },
};
</script>
<style>
.c-chart {
  width: 100%;
  max-width: 400px;
}
</style>
